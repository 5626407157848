.user-management-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.user-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.invite-user-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.invite-form {
  position: relative;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.invite-form .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #333;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .invite-form .close-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

.invite-form-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.invite-form input,
.invite-form select {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
}

.send-invite-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.action-buttons button {
  margin-right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.action-buttons button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.action-buttons .invitation-edit-button { color: #2196F3; }
.action-buttons .resend-button { color: #4CAF50; }
.action-buttons .delete-button { color: #f44336; }

.invite-form-content label {
  margin-right: 5px;
}

.invite-form-content input,
.invite-form-content select {
  flex: 1;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.status-toggle-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.status-toggle-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.status-toggle-button.activate {
  color: #4CAF50;
}

.status-toggle-button.deactivate {
  color: #f44336;
}

.status-toggle-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.edit-role-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #2196F3;
  font-size: 14px;
  padding: 2px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.edit-role-button:hover {
  background-color: rgba(33, 150, 243, 0.1);
}

.search-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
  }
  
  .search-input {
    width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .user-table th {
    cursor: pointer;
  }
  
  .user-table th:hover {
    background-color: #e0e0e0;
  }
  
  .user-table th span {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    margin-left: 5px;
  }
  
  .user-table th span.asc {
    border-bottom: 5px solid #333;
  }
  
  .user-table th span.desc {
    border-top: 5px solid #333;
  }