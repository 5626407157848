.ukonlayout-container {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 60px;
    background-color: #f8f9fa;
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
}

.sidebar-header {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.sidebar-logo {
    max-width: 100%;
    height: auto;
}

.sidebar-nav {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

.nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s;
}

.nav-item:hover {
    background-color: #e9ecef;
}

.nav-item i {
    font-size: 20px;
}

.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.main-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
}

.user-menu {
    display: flex;
    align-items: center;
}

.user-menu span {
    margin-right: 10px;
}

.user-avatar {
    font-size: 24px;
    color: #333;
}

.content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.avatar-dropdown {
    position: relative;
}

.user-avatar {
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown-item {
    display: block;
    padding: 10px 15px;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
}

button.dropdown-item {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    font-size: inherit;
    cursor: pointer;
}

.nav-item.active {
    background-color: #e9ecef;
    border-left: 3px solid #007bff;
}

.nav-item.active i {
    color: #007bff;
}