/* Add these styles at the top of your file */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.home-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #333;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
  }
  
  .home-header {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
}
  
  .logo {
    height: 40px;
    width: auto;
  }
  
  nav {
    display: flex;
    gap: 20px;
  }
  
  nav a {
    text-decoration: none;
    color: #333;
  }
  
  .login-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
  }

  .login-button:hover {
    background-color: #0056b3;
  }
  
  .home-main {
    flex: 0 0 auto;
    max-width: 80%;
    text-align: center;
    padding: 150px 0;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 40px;
  }
  
  .cta-button {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 12px 24px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .features-section {
    flex: 1 0 auto;
    width: 100%;
    background-color: #333333;
    color: white;
    padding: 40px 0;
  }
  
  .feature-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .feature {
    flex: 1 1 300px;
    max-width: 100%;
  }
  
  .feature h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .feature p {
    font-size: 1rem;
    color: #ffffff;
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }

    .subtitle {
      font-size: 1rem;
    }

    .features-section {
      flex-direction: column;
    }

    .feature {
      flex-basis: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .home-header {
      gap: 20px;
    }

    h1 {
      font-size: 1.75rem;
    }

    .subtitle {
      font-size: 0.9rem;
    }

    .cta-button {
      width: 80%;
    }
  }