.settings-tabs {
    display: flex;
    flex-direction: column;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 2px solid #ddd;
  }
  
  .tabs button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    background-color: #87b3bd;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-bottom: 2px solid transparent;
    flex: 1; /* Ensure buttons are separated */
  }
  
  .tabs button:hover {
    background-color: #e0e0e0;
  }
  
  .tabs button.active {
    background-color: #075a51;
    color: white;
    border-bottom: 2px solid #0056b3;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: white;
  }
  
  .tab-content-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .locations-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .locations-header h3 {
    flex-grow: 1;
    text-align: center;
    margin: 0;
  }
  
  .add-location-button {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #007bff;
  }
  
  .add-location-button:hover {
    color: #0056b3;
  }
  
  .locations-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .location-item {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .location-info {
    flex-grow: 1;
  }

  .location-info h4 {
    margin: 0 0 0.5rem 0;
    font-size: 1.1rem;
  }

  .location-type {
    font-style: italic;
    color: #666;
    margin: 0 0 0.5rem 0;
  }

  .location-address {
    margin: 0;
    font-size: 0.9rem;
    color: #333;
  }

  .location-actions {
    display: flex;
    gap: 0.5rem;
  }

  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #007bff;
    transition: color 0.3s;
  }

  .icon-button:hover {
    color: #0056b3;
  }

  .delete-button {
    color: #dc3545;
  }

  .delete-button:hover {
    color: #bd2130;
  }

  .no-locations {
    text-align: center;
    color: #666;
    font-style: italic;
    margin-top: 2rem;
  }

  .location-edit-button {
    color: #007bff;
  }

  .location-edit-button:hover {
    color: #0056b3;
  }

.location-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-row label {
  flex: 0 0 120px;
  margin-right: 10px;
  text-align: right;
}

.form-row input,
.form-row select {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.address-line {
  flex-wrap: wrap;
}

.address-line label {
  flex: 0 0 80px;
}

.address-line input {
  flex: 0 0 calc(28% - 90px);
  margin-right: 10px;
}

.form-separator {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ccc;
}

.tab-content button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 130px;
}

.tab-content button[type="submit"]:hover {
  background-color: #0056b3;
}

.location-form button[type="submit"] {
  display: block;
  width: auto;
  margin: 20px auto 0;
  padding: 8px 20px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.location-form button[type="submit"]:hover {
  background-color: #0056b3;
}

.form-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.form-header h4 {
  margin: 0;
  text-align: center;
}

.close-form-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.tab-content button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 130px;
}

.tab-content button[type="submit"]:hover {
  background-color: #0056b3;
}