.accept-invitation-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
  }
  
  .accept-invitation-container {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
    position: relative;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  input {
    width: 100%;
    padding: 10px 30px 10px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .input-with-check {
    position: relative;
  }
  
  .check {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #27ae60;
    font-size: 18px;
  }
  
  .password-strength, .password-match {
    font-size: 12px;
    margin-top: 5px;
    font-weight: bold;
  }
  
  .password-strength.weak { color: #e74c3c; }
  .password-strength.medium { color: #f39c12; }
  .password-strength.strong { color: #27ae60; }
  
  .password-match.match { color: #27ae60; }
  .password-match.no-match { color: #e74c3c; }
  
  .accept-button {
    width: 100%;
    padding: 10px;
    background-color: #1877f2;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .accept-button:hover {
    background-color: #166fe5;
  }
  
  .accept-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .error {
    color: #e74c3c;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .success {
    color: #27ae60;
    text-align: center;
    margin-bottom: 15px;
  }