.language-switcher {
  display: flex;
  gap: 5px;
}

.language-switcher button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.language-switcher button:hover,
.language-switcher button.active {
  background-color: rgba(0, 0, 0, 0.1);
}
