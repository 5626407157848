.signup-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.signup-container {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  position: relative;
}

.go-back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: #1877f2;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
}

.go-back-button:hover {
  text-decoration: underline;
}

.arrow-left {
  margin-right: 5px;
  font-size: 18px;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
  position: relative;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

input, select, textarea {
  width: 100%;
  padding: 10px 30px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.input-with-check {
  position: relative;
}

.check {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #27ae60;
  font-size: 18px;
}

.input-with-check input + .check {
  right: 10px;
}

.input-with-check select + .check {
  right: 30px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #1877f2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #166fe5;
}

button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error {
  color: #e74c3c;
  text-align: center;
  margin-bottom: 15px;
}

.password-strength {
  font-size: 12px;
  margin-top: 5px;
  font-weight: bold;
}

.password-strength.weak {
  color: #e74c3c;
}

.password-strength.medium {
  color: #f39c12;
}

.password-strength.strong {
  color: #27ae60;
}

.email-availability {
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.email-availability .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.email-availability.available {
  color: green;
}

.email-availability.available .dot {
  background-color: green;
}

.email-availability.not-available {
  color: red;
}

.email-availability.not-available .dot {
  background-color: red;
}

.input-group small {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  display: block;
}

.password-match {
  font-size: 12px;
  margin-top: 5px;
}

.password-match.match {
  color: green;
}

.password-match.no-match {
  color: red;
}

select {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%33333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
}

.input-with-check select {
  padding-right: 50px;
}

.registration-status {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.registration-status.success {
  background-color: #d4edda;
  color: #155724;
}

.registration-status.error {
  background-color: #f8d7da;
  color: #721c24;
}

.complete-registration-link {
  display: inline-block;
  width: 50%;
  padding: 10px;
  background-color: #1877f2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  text-decoration: none;
  margin: 0 auto;
}

.complete-registration-link:hover {
  background-color: #166fe5;
}

.registration-complete {
  text-align: center;
}

.registration-complete .registration-status {
  margin-bottom: 20px;
}