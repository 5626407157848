.user-profile {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.user-profile h1 {
  color: #333;
  margin-bottom: 20px;
}

.edit-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
}

.profile-details {
  display: flex;
  gap: 20px;
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  position: relative;
}

.profile-avatar img,
.profile-avatar i {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-avatar i {
  font-size: 50px;
  color: #999;
}

.profile-avatar input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.profile-avatar.editing::before {
  content: '\f030';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.profile-avatar.editing:hover::before {
  opacity: 1;
}

.profile-info {
  flex: 1;
}

.info-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-row label {
  width: 140px;
  font-weight: bold;
  font-size: 16px;
}

.info-row input,
.info-row select {
  width: 200px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.profile-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.save-button,
.cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100px;
}

.save-button {
  background-color: #007bff;
  color: white;
}

.cancel-button {
  background-color: #dc3545;
  color: white;
}

.save-button:hover,
.cancel-button:hover {
  opacity: 0.9;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  text-align: center;
}

.password-change-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.password-change-section h3 {
  margin-bottom: 15px;
  text-align: center;
}

.password-change-section .info-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.password-change-section .info-row label {
  width: 180px;
  text-align: right;
  margin-right: 10px;
}

.password-change-section .info-row input {
  width: 200px;
}

.password-input-wrapper {
  display: flex;
  align-items: center;
}

.password-strength {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  width: 80px;
}

.password-strength.weak {
  color: #dc3545;
}

.password-strength.medium {
  color: #ffc107;
}

.password-strength.strong {
  color: #28a745;
}

.change-password-button {
  background-color: #28a745;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 200px;
  margin: 20px auto 0;
  display: block;
}

.change-password-button:hover {
  background-color: #218838;
}
